.horizontal-scroll-container {
  display: flex;
  overflow-x: auto;
  padding: 10px;
  white-space: nowrap;
}

.capsule-box {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 50px;
  padding: 10px 20px;
  margin-right: 10px;
  display: inline-block;
  white-space: normal; /* Allows text to wrap within the capsule */
  flex: 0 0 auto; /* Prevents flex items from shrinking */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
  width: 400px; /* Fix the width */
  max-height: 450px; /* Max height of the capsule */
  overflow-y: auto; /* Enable vertical scroll */
  text-align: center; /* Center the text */
}

.capsule-title {
  font-size: 18px;
  font-weight: bold;
}

.capsule-box hr {
  border-top: 2px solid #000;
  margin: 10px 0;
}

.capsule-content p {
  margin: 0;
  padding: 0;
  word-wrap: break-word; /* Allows text to break and fit within the box */
}
