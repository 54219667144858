/* Gochat.css */
.background-image {
    background-image: url("assets/img/antoine-barres.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* Ensure relative positioning */
  }
  
  
  .background-image::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity : 0.6
  }
  
  .chat-interface-container {
    position: absolute;
    max-height: fit-content;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%; /* Adjust width as needed */
    max-width: 500px; /* Limit the maximum width */
    background-color: rgba(255, 255, 255, 0.9); /* Adjust the opacity as needed */
    padding: 20px;
    border-radius: 10px;
  }

  .background-plain {
    background-image: url("imgs/images.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      /* Ensure relative positioning */
  }